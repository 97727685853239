<template>
  <div class="page-container app-vh100" flex>
    <div class="app-mg-auto">
      <el-card style="width: 600px">
        <div class="title app-text-center">PS游戏后台管理系统</div>
        <el-form
          :model="formData"
          :rules="rules"
          ref="loginForm"
          @keyup.enter.native="submitForm('loginForm')"
        >
          <el-form-item label="用户名" prop="username">
            <el-input
              v-model="formData.username"
              placeholder="请输入用户名"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              v-model="formData.password"
              type="password"
              auto-complete="off"
              placeholder="请输入密码"
              clearable
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <div flex class="app-w100">
              <div flex-box="8">
                <el-input
                  v-model="formData.code"
                  placeholder="请输入验证码"
                  clearable
                ></el-input>
              </div>
              <div flex-box="2" class="app-text-center">
                <img
                  class="img-captcha"
                  :src="captchaUrl"
                  alt="captcha"
                  @click="refreshCaptcha()"
                />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('loginForm')"
              >登录</el-button
            >
            <el-button @click="resetForm('loginForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "login",
  data() {
    return {
      captchaUrl: "",
      formData: {
        username: "",
        password: "",
        code: "",
      },
      rules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  created() {
    this.refreshCaptcha();
  },
  methods: {
    ...mapActions(["login", "getMenu"]),
    refreshCaptcha() {
      this.captchaUrl =
        this.mCommonBaseURL + "/captcha/create" + "?t=" + new Date().getTime();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 进行登录
          let loading = this.$loading();
          this.login(this.formData).then((res) => {
            loading.close();
            if (res.code != 0) {
              this.mToast(res.message, "error");
              // 刷新验证码
              this.refreshCaptcha();
            } else {
              this.getMenu();
              this.$router.push("/");
            }
          });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 24px;
  font-weight: bold;
  padding: 50px 0;
}
.img-captcha {
  border-radius: 2px;
  cursor: pointer;
}
</style>